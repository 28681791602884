@import './variables.scss';

html {
  overflow: hidden;
}

.App {
  z-index: -1;
  height: 100%;
  font-size: 1.4vw;

  @media (max-width: 1920px) {
    font-size: 1.2em;
  }

  @font-face {
    font-family: 'yanone-kaffeesatz';

    src: url('./fonts/yanonekaffeesatz-regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'yanone-kaffeesatz';
    src: url('./fonts/yanonekaffeesatz-bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Metropolis';
    src: url('./fonts/Metropolis-Regular.eot');
    src:
      url('./fonts/Metropolis-Regular.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Metropolis-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Metropolis';
    src: url('./fonts/Metropolis-Black.eot');
    src:
      url('./fonts/Metropolis-Black.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Metropolis-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  .MiniPlayerContainer-dragContainer {
    height: fit-content;
    position: absolute;
    right: 5px;
    bottom: 70px;
    width: calc(100vw - 10px);
  }

  .SuspenseLoader {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  @media (min-width: $breakpoint-tablet) {
    .MiniPlayerContainer-dragContainer {
      right: 50px;
      bottom: 150px;
      width: 700px;
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .d-flex {
    display: flex;
  }

  .align-center {
    align-items: center;
  }

  .justify-center {
    justify-content: center;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .text-center {
    text-align: center;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
}
