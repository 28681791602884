@import '../../../../variables';

.MyAccountWeb {
  padding: 1em;

  @media (min-width: $breakpoint-tablet) {
    .BannerSetView {
      margin-top: 70px;
    }
  }

  .Infos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: $dark-black;
    color: $white;
    margin-bottom: 30px;

    @media (min-width: $breakpoint-tablet) {
      padding: 0.6em 1em;
      justify-content: space-between;
      position: absolute;
      top: 65px;
      left: 0;
      right: 0;
      z-index: 4;
    }

    .NavigationSection {
      border: 3px solid rgba(209, 0, 0, 0.75);
      background-color: rgba(209, 0, 0, 0.5);
      border-radius: 10px;
      padding: 0.4em 0.6em;
      text-transform: uppercase;
      font-weight: 700;
      margin-right: 10px;
      font-size: 85%;
      cursor: pointer;

      &:hover {
        color: #fff;
      }
    }
  }
}
