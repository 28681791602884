@import 'src/variables';

.MainSearch {
  text-transform: uppercase;
  font-family: Arial;

  .SearchInputContainer {
    width: 100%;

    @media (min-width: $breakpoint-tablet) {
      & {
        position: relative;
      }
    }
  }

  .DropdownSelect {
    padding: 1% 2%;
    color: #fff;
    user-select: none;
    font-family: 'Metropolis', 'Helvetica Neue', sans-serif;

    @media (max-width: $breakpoint-mobile) {
      & {
        position: static;
      }
    }
  }

  .searchTerm {
    padding: .5em;
    outline: none;
    border: none;
    background-color: transparent;
    text-transform: inherit;
    width: 100%;
    font: inherit;
    box-sizing: border-box; 
    color: $white;
  }

  .SearchContainer {
    display: none;
    border-bottom: 2px solid $dark-blue;
    width: 100vw;
    position: relative;

    @media (min-width: $breakpoint-tablet) {
      & {
        max-width: 30vw;
      }
    }

    @media (max-width: $breakpoint-tablet) {
      & {
        display: flex;
      }
    }

    &.hasDropdown {
      font-weight: 800;
      border: none;
      border-bottom: 2px solid $dark-blue;

      @media (max-width: $breakpoint-tablet) {
        & {
          font-size: 90%;
        }
      }
    }
  }

  &.isOpen {
    .SearchContainer {
      display: flex;
    }
  }

  .searchButton {
    margin: 0 10px;
    color: $dark-blue;
    cursor: pointer;
    font-size: 120%;

    @media (max-width: $breakpoint-tablet) {
      & {
        display: none;
      }
    }
  }

  .SearchResults {
    padding: 10px;
    background: $secondary;
    // outline: 2px solid $dark-blue;
    position: absolute;
    z-index: 2000;
    // font-weight: 600;
    width: 100%;
    top: 105%;
    left: 0;
    user-select: none;
    max-height: 400px;
    overflow: auto;
    box-sizing: border-box;

    .SearchResultsSection {
      display: flex;
      flex-direction: column;
      font-size: 90%;

      .SearchResultsSectionHeader {
        color: $gray;
      }

      .SearchResultItem {
        cursor: pointer;
        margin-bottom: 5px;
        color: $dark-blue;
        margin-left: 15px;
        text-transform: capitalize;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .NoResults {
    position: absolute;
    z-index: 2000;
    top: 105%;
    left: 0;
    // outline: 2px solid $dark-blue;
    padding: 10px;
    background: $brownColor;
    color: $dark-blue;
    width: 100%;
    box-sizing: border-box;
  }
}