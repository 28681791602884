body {
  margin: 0;
  font-family:
    'Oswald',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
/* 'Comic Sans MS', Comic Sans,  */

/* fonts */

@font-face {
  font-family: 'Yanone Kaffeesatz';
  font-display: swap;
  src:
    url('./fonts/yanonekaffeesatz-regular.ttf') format('truetype'),
    url('./fonts/yanonekaffeesatz-regular.woff') format('woff'),
    url('./fonts/yanonekaffeesatz-regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Yanone Kaffeesatz Bold';
  font-display: swap;
  src:
    url('./fonts/yanonekaffeesatz-bold.ttf') format('truetype'),
    url('./fonts/yanonekaffeesatz-bold.woff') format('woff'),
    url('./fonts/yanonekaffeesatz-bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('./fonts/Metropolis-Regular.eot');
  src:
    url('./fonts/Metropolis-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Metropolis-Regular.ttf') format('truetype'),
    url('./fonts/Metropolis-Regular.woff') format('woff'),
    url('./fonts/Metropolis-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis-Black';
  src: url('./fonts/Metropolis-Black.eot');
  src:
    url('./fonts/Metropolis-Black.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Metropolis-Black.ttf') format('truetype'),
    url('./fonts/Metropolis-Black.woff') format('woff'),
    url('./fonts/Metropolis-Black.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

/*.MainDashboardView  */

.MainDashboardView .video-js .vjs-tech {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*.MainDashboardView  */

.MovieDetailsPage .video-js .vjs-tech {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
