@import '../../variables.scss';

.Arrow {
  width: 0;
  height: 0;
  cursor: pointer;
  border-right: $arrow-clear;
  border-top: $arrow-color;
  border-left: $arrow-clear;
  &:hover {
    border-top: unset;
    border-bottom: 5px solid #9b9b9b;
  }
  &.Show {
    border-top: unset;
    border-right: 6px solid transparent;
    border-bottom: 10px solid #9b9b9b;
    border-left: 6px solid transparent;
  }
}
