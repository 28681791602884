@import '../../variables';

// sign in as a modal
.SignInModal,
.ModalDialog.SignInModal {
  background-color: $black;
  user-select: none;
  height: 100vh;

  .SignIn-inner {
    width: 90%;
    max-height: 65vh;
    overflow: auto;
    color: $white;

    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    & {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }

    @media (min-width: $breakpoint-tablet) {
      width: 100%;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    padding: 1.5em 2em;
    width: 100vw;
    max-width: 16em;
    left: calc(100vw - 580px);
    height: auto;
  }
}

.InputWrapper {
  background-color: $white;
  color: $font-awesome-icon-color;

  .SignInInput {
    width: 100%;
    margin: 0 .3em;
    padding: 0 .3em;
    outline: none;
    border: none;
    font-family: $font-family-metropolis;
    font-size: 80%;
  }
}

.SignInButton {
  color: $black;
  background-color: $primary;
  font-weight: bold;
  cursor: pointer;
  font: inherit;
  width: 100%;

  &:hover {
    &:not([disabled='disabled']) {
      color: $white;
    }
  }

  & [disabled='disabled'] {
    cursor: not-allowed;
  }
}

.InputWrapper,
.SignInButton {
  border: none;
  text-transform: uppercase;
  border-radius: 0.3em;
  margin-bottom: .5em;
  padding: .5em;
  line-height: 1;
}

.JoinHere {
  margin-left: 0.5em;
  text-transform: uppercase;

  &:hover {
    opacity: 0.7;
  }
}

.ForgotPswContainer {
  position: relative;
  text-align: center;

  .IconsContainer {
    font-size: 90%;
    color: $gray;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  .MemberPWReminder {
    margin: 0;
    padding: 0;
    background: unset;
    line-height: 1;
    max-width: unset;

    &>span {
      font-size: 70%;
    }
  }
}

.InvalidDataContainer {
  border-radius: 3px;
  padding: 20px;
  background-color: $sign-in-error-background;
  margin-bottom: 20px;
  font-size: .6em;
  color: $white;
  text-align: center;

  .fa-exclamation-triangle {
    font-size: 2em;
  } 
}

// sign in as a modal end

// AVS Page
.SignIn {
  color: #000;
  background: rgba(0, 0, 0, 0.05);
  padding: 1em;
  border-radius: 0.5em;
  max-width: 20em;
  margin: auto;

  .SignInButton {
    background-color: rgba(0, 0, 0, .2);
    color: $white;
  }
}